import { useEffect, useRef, useState } from 'react';

import FormRequestBlock from 'components/FormRequestBlock';

import './style.scss';

import FormInput from 'components/FormInput';
import FormButton from 'components/FormButton';
import { requestAccountAccess } from 'app/amazon/auth';
import { AmazonBusinessAccount } from 'app/firebase/collections/amazonBusinessAccounts';
import {
  fetchActiveAmazonBusinessAccount,
  fetchAmazonBusinessAccounts,
  selectActiveAmazonBusinessAccountEmailId,
  selectAmazonBusinessAccounts,
} from 'app/store/amazonBusinessAccounts';
import { useAppDispatch, useAppSelector } from 'app/store';
import { AmazonBusinessConfig } from 'app/graphql/generated/admin/graphql';
import { useQuery } from '@apollo/client/react';
import { ADMIN_USER } from 'app/graphql';
import { selectUserInfo } from 'app/store/user';

const AmazonBusinessGroup = ({
  amazonBusinessConfig,
  userId,
}: {
  amazonBusinessConfig: AmazonBusinessConfig;
  userId: string;
}) => {
  const dispatch = useAppDispatch();

  const [formLoading, setFormLoading] = useState(false);
  const [amazonEmail, setAmazonEmail] = useState(amazonBusinessConfig.email ?? '');
  const { adminToken, isReadOnly } = useAppSelector(selectUserInfo) || {};

  const [defaultAmazonBusinessAccount, setDefaultAmazonBusinessAccount] =
    useState(amazonBusinessConfig);

  const activeAmazonBusinessEmailId = useAppSelector(selectActiveAmazonBusinessAccountEmailId);
  const additionalAmazonBusinessAccounts = useAppSelector(selectAmazonBusinessAccounts);
  const [additionalAccounts, setAdditionalAccounts] = useState<AmazonBusinessAccount[]>(
    additionalAmazonBusinessAccounts,
  );
  const loadingTimeout = useRef<ReturnType<typeof setTimeout>>();

  const [newActiveAmazonBusinessEmailId, setNewActiveAmazonBusinessEmailId] = useState<
    string | undefined
  >();

  const { refetch } = useQuery(ADMIN_USER, {
    skip: !adminToken,
    context: {
      clientName: 'admin',
    },
  });

  useEffect(() => {
    dispatch(fetchAmazonBusinessAccounts(userId));
    dispatch(fetchActiveAmazonBusinessAccount(userId));

    if (!newActiveAmazonBusinessEmailId && activeAmazonBusinessEmailId) {
      setNewActiveAmazonBusinessEmailId(activeAmazonBusinessEmailId);
    }
    setAdditionalAccounts(additionalAmazonBusinessAccounts);
  }, [userId, activeAmazonBusinessEmailId, additionalAmazonBusinessAccounts]);

  const handleEmailRequest = (isAdditional: boolean) => async (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (isReadOnly) return;

    setFormLoading(true);
    const data = await refetch();
    const user = data?.data.user.data?.user;
    const email = amazonEmail.trim();
    if (email && user?.amazonBusinessConfig) {
      await requestAccountAccess(userId, email, isAdditional);
    }
    loadingTimeout.current = setTimeout(() => {
      setFormLoading(false);
      if (isAdditional) {
        setAdditionalAccounts((prev) => [
          ...prev,
          { businessType: 'group_account', email, pendingGroupAccess: true },
        ]);
      } else {
        setDefaultAmazonBusinessAccount({
          ...amazonBusinessConfig,
          email: email,
          pendingGroupAccess: true,
        });
      }
    }, 1000);
  };

  return (
    <FormRequestBlock
      styles={{ form: { flexDirection: 'column', gap: '0px', marginTop: 0 } }}
      title={'Amazon Access'}
      text={
        <>
          {!defaultAmazonBusinessAccount?.email ? (
            <>
              Enter an email to request provisioning of an Amazon Business account under Rye.
              <br />
              <br />
              Once the account is provisioned, you will receive an email with instructions on
              setting up your account. Use an email that does not have an existing Amazon Business
              account.
            </>
          ) : (
            <>Below is a list of your Amazon accounts Rye uses to place orders.</>
          )}
        </>
      }
      loading={formLoading}
    >
      <div className="amazon-business-form">
        {!defaultAmazonBusinessAccount.email && (
          <div className="amazon-business-body">
            <FormInput
              id="amazon-business-input-email"
              className="amazon-business-input"
              type="text"
              defaultValue={amazonBusinessConfig.email}
              placeholder="Email"
              disabled={isReadOnly}
              onChange={(value) => setAmazonEmail(value)}
            />

            <FormButton disabled={formLoading || isReadOnly} onClick={handleEmailRequest(false)}>
              Request
            </FormButton>
          </div>
        )}
      </div>

      {defaultAmazonBusinessAccount.email && (
        <div className="amazon-business-accounts">
          <div className="section-title">
            <h4>Main Amazon account</h4>{' '}
          </div>

          <div className="amazon-business-accounts-radio-button-row">
            {defaultAmazonBusinessAccount.email}
            {defaultAmazonBusinessAccount.pendingGroupAccess && <> (Pending)</>}
          </div>

          {additionalAccounts && additionalAccounts.length > 0 && (
            <div className="amazon-business-accounts">
              <h4>Backup Amazon accounts</h4>

              {additionalAccounts.map((account, index) => (
                <>
                  <div key={index} className="amazon-business-accounts-radio-button-row">
                    {account.email}
                  </div>
                </>
              ))}
            </div>
          )}
        </div>
      )}
    </FormRequestBlock>
  );
};

export default AmazonBusinessGroup;
