import { ReactComponent as LinkIcon } from 'assets/icons/link.svg';
import FormButton from 'components/FormButton';
import FormInput from 'components/FormInput';
import PreloaderIcon from 'components/Preloader';

import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';

import './style.scss';

interface FormRequestBlockProps {
  id?: string; // for heap analytics mostly
  children?: React.ReactNode;
  title: string;
  statusDetail?: string | React.ReactNode;
  text: string | React.ReactNode;
  placeholder?: string;
  inputValue?: string;
  submitValue?: string;
  disabled?: boolean;
  loading?: boolean;
  type?: 'text' | 'email' | 'url' | 'number' | 'tel';
  required?: boolean;
  pattern?: string;
  onSubmit?: (e: React.SyntheticEvent) => void;
  onChange?: (value: string) => void;
  styles?: { form?: React.CSSProperties };
  error?: string;
  multiline?: boolean;
  rows?: number;
}

const FormRequestBlock = ({
  children,
  title,
  statusDetail,
  text,
  placeholder,
  required,
  inputValue = '',
  submitValue = 'Save',
  disabled = false,
  loading = false,
  type = 'text',
  pattern,
  styles,
  onSubmit = () => {
    return;
  },
  onChange = () => {
    return;
  },
  error,
  multiline,
  rows,
}: FormRequestBlockProps) => {
  const formClass = `form-request-block${loading ? ' loading' : ''}`;
  return (
    <form className={formClass} onSubmit={onSubmit}>
      <label>
        <div className="form-request-block-preloader">
          <PreloaderIcon />
        </div>
        <div className="flex w-full flex-row">
          <h3 className="form-request-block-title flex-1">{title}</h3>
          {statusDetail && <div className="form-request-block-status">{statusDetail}</div>}
        </div>

        <p className="form-request-block-text">{text}</p>
        <div className="form-request-block-form" style={styles?.form}>
          {children || (
            <>
              <LinkIcon className="form-request-block-icon" title={'Link'} />
              <FormInput
                type={type}
                required={required}
                pattern={pattern}
                className="form-request-block-input"
                placeholder={placeholder}
                onChange={onChange}
                value={inputValue}
                disabled={loading || disabled}
                multiline={multiline}
                rows={rows}
              />
              <FormButton disabled={loading || disabled}>{submitValue}</FormButton>
            </>
          )}
        </div>
      </label>

      {error && (
        <div className="form-request-block__error">
          <WarningIcon width={60} height={60} /> <span style={{ marginLeft: '8px' }}>{error}</span>
        </div>
      )}
    </form>
  );
};

export default FormRequestBlock;
