/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  ConnectionCursor: { input: string; output: string };
  /** A date without time that follows the RFC3339Nano spec. Example: '2022-09-18' */
  Date: { input: string; output: string };
  /** A date with time that follows the RFC3339Nano spec. Example: '2022-09-18T23:12:23-06:00' */
  DateTime: { input: string; output: string };
  Email: { input: string; output: string };
  /** A percentage represented as an integer. Example: 5 represents 5% */
  Percentage: { input: number; output: number };
  /** A valid URL with a scheme of http, https */
  URL: { input: any; output: any };
};

export type AmazonBusinessConfig = {
  __typename?: 'AmazonBusinessConfig';
  activeAmazonBusinessEmailId?: Maybe<Scalars['String']['output']>;
  businessType?: Maybe<AmazonBusinessType>;
  email: Scalars['String']['output'];
  groupId?: Maybe<Scalars['String']['output']>;
  orderSplit?: Maybe<Scalars['Int']['output']>;
  parentAmazonAccountEmail?: Maybe<Scalars['String']['output']>;
  pendingGroupAccess?: Maybe<Scalars['Boolean']['output']>;
  token?: Maybe<AmazonToken>;
};

export enum AmazonBusinessType {
  FirstParty = 'FIRST_PARTY',
  GroupAccount = 'GROUP_ACCOUNT',
  ThirdParty = 'THIRD_PARTY',
}

export type AmazonProduct = Product & {
  __typename?: 'AmazonProduct';
  productId: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
};

export type AmazonToken = {
  __typename?: 'AmazonToken';
  status: AmazonTokenStatus;
  type: AmazonTokenType;
};

export enum AmazonTokenStatus {
  Active = 'ACTIVE',
  Revoked = 'REVOKED',
}

export enum AmazonTokenType {
  Bearer = 'BEARER',
}

export type Amount = {
  __typename?: 'Amount';
  currency: Currency;
  value: Scalars['Float']['output'];
};

export type AmountInput = {
  currency: Currency;
  value: Scalars['Float']['input'];
};

export enum ApiType {
  SellAnything = 'SELL_ANYTHING',
  SyncApi = 'SYNC_API',
}

export type AttachPaymentMethodInput = {
  billingDetails: BillingDetailsInput;
  /** Payment method id */
  id: Scalars['ID']['input'];
};

export type AttachPaymentMethodResponse = {
  __typename?: 'AttachPaymentMethodResponse';
  stripeConfig: StripeConfig;
};

export enum AuthProvider {
  Firebase = 'FIREBASE',
  Google = 'GOOGLE',
  Local = 'LOCAL',
}

export type BillingDetailsInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type BillingFiltersInput = {
  amount?: InputMaybe<AmountInput>;
  endDate: Scalars['DateTime']['input'];
  orderIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  payers?: InputMaybe<Array<BillingItemParty>>;
  receivers?: InputMaybe<Array<BillingItemParty>>;
  startDate: Scalars['DateTime']['input'];
  types?: InputMaybe<Array<BillingItemType>>;
};

export type BillingItem = {
  __typename?: 'BillingItem';
  amount: Amount;
  billingSetting: BillingSetting;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  orderId: Scalars['ID']['output'];
  payer: BillingItemParty;
  receiver: BillingItemParty;
  type: BillingItemType;
};

export type BillingItemEdge = {
  __typename?: 'BillingItemEdge';
  cursor: Scalars['ConnectionCursor']['output'];
  node: BillingItem;
};

export enum BillingItemParty {
  Affiliate = 'AFFILIATE',
  Developer = 'DEVELOPER',
  Merchant = 'MERCHANT',
  PayerUnknown = 'PAYER_UNKNOWN',
  Rye = 'RYE',
  Shopper = 'SHOPPER',
  Unrecognized = 'UNRECOGNIZED',
}

export type BillingItemResponse = {
  __typename?: 'BillingItemResponse';
  edges: Array<BillingItemEdge>;
  pageInfo: PageInfo;
};

export enum BillingItemType {
  AffiliateCommission = 'AFFILIATE_COMMISSION',
  AffiliateCommissionDeveloperShare = 'AFFILIATE_COMMISSION_DEVELOPER_SHARE',
  AffiliateCommissionRyeShare = 'AFFILIATE_COMMISSION_RYE_SHARE',
  Credit = 'CREDIT',
  DeveloperFulfilled = 'DEVELOPER_FULFILLED',
  DiscountCommission = 'DISCOUNT_COMMISSION',
  DiscountCommissionDeveloperShare = 'DISCOUNT_COMMISSION_DEVELOPER_SHARE',
  DiscountCommissionRyeShare = 'DISCOUNT_COMMISSION_RYE_SHARE',
  FulfillmentFee = 'FULFILLMENT_FEE',
  MarkupCommission = 'MARKUP_COMMISSION',
  MarkupCommissionDeveloperShare = 'MARKUP_COMMISSION_DEVELOPER_SHARE',
  MarkupCommissionRyeShare = 'MARKUP_COMMISSION_RYE_SHARE',
  MerchantCommission = 'MERCHANT_COMMISSION',
  MerchantCommissionDeveloperShare = 'MERCHANT_COMMISSION_DEVELOPER_SHARE',
  MerchantCommissionRyeShare = 'MERCHANT_COMMISSION_RYE_SHARE',
  Order = 'ORDER',
  PlatformFee = 'PLATFORM_FEE',
  PlatformFeeRefund = 'PLATFORM_FEE_REFUND',
  Refund = 'REFUND',
}

export type BillingItemsPaginationInput = {
  after?: InputMaybe<Scalars['ConnectionCursor']['input']>;
  before?: InputMaybe<Scalars['ConnectionCursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type BillingNotifications = {
  __typename?: 'BillingNotifications';
  emails?: Maybe<Array<Scalars['String']['output']>>;
};

export type BillingPerGmv = BillingPeriodSettings & {
  __typename?: 'BillingPerGMV';
  currency: Currency;
  gracePeriodDays: Scalars['Int']['output'];
  lastInvoiceDate: Scalars['DateTime']['output'];
  remainingGMV: Scalars['Int']['output'];
};

export type BillingPerPeriod = BillingPeriodSettings & {
  __typename?: 'BillingPerPeriod';
  days: Scalars['Int']['output'];
  endDate: Scalars['DateTime']['output'];
  gracePeriodDays: Scalars['Int']['output'];
  startDate: Scalars['DateTime']['output'];
};

export type BillingPeriodSettings = {
  gracePeriodDays: Scalars['Int']['output'];
};

export type BillingPrePayment = BillingPeriodSettings & {
  __typename?: 'BillingPrePayment';
  currency: Currency;
  gracePeriodDays: Scalars['Int']['output'];
  lastRefillDate: Scalars['DateTime']['output'];
  minBalance: Scalars['Int']['output'];
  remainingBalance: Scalars['Int']['output'];
};

export type BillingSetting = {
  __typename?: 'BillingSetting';
  developerCommissionShare: Scalars['Int']['output'];
  merchantCommissionRate: Scalars['Int']['output'];
  volumeTiers?: Maybe<Array<VolumeTier>>;
};

export type BillingTransfer = {
  __typename?: 'BillingTransfer';
  amount: Amount;
  note: Scalars['String']['output'];
  payer: BillingItemParty;
  receiver: BillingItemParty;
  timestamp: Scalars['DateTime']['output'];
  type: BillingTransferType;
};

export enum BillingTransferType {
  Cancelled = 'CANCELLED',
  Commission = 'COMMISSION',
  Payment = 'PAYMENT',
  Refund = 'REFUND',
}

export type Commission = {
  __typename?: 'Commission';
  rate: Scalars['Int']['output'];
  status: CommissionStatus;
};

export enum CommissionStatus {
  Discounted = 'DISCOUNTED',
  Pending = 'PENDING',
  Sent = 'SENT',
  Skipped = 'SKIPPED',
}

export type CreateDeveloperTokenInput = {
  developerId: Scalars['ID']['input'];
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

/** ISO currency code. */
export enum Currency {
  /** United Arab Emirates Dirham. */
  Aed = 'AED',
  /** Afghan Afghani. */
  Afn = 'AFN',
  /** Albanian Lek. */
  All = 'ALL',
  /** Armenian Dram. */
  Amd = 'AMD',
  /** Netherlands Antillean Gulden. */
  Ang = 'ANG',
  /** Angolan Kwanza. */
  Aoa = 'AOA',
  /** Argentine Peso. */
  Ars = 'ARS',
  /** Australian Dollar. */
  Aud = 'AUD',
  /** Aruban Florin. */
  Awg = 'AWG',
  /** Azerbaijani Manat. */
  Azn = 'AZN',
  /** Bosnia and Herzegovina Convertible Mark. */
  Bam = 'BAM',
  /** Barbadian Dollar. */
  Bbd = 'BBD',
  /** Bangladeshi Taka. */
  Bdt = 'BDT',
  /** Bulgarian Lev. */
  Bgn = 'BGN',
  /** Bahraini Dinar. */
  Bhd = 'BHD',
  /** Burundian Franc. */
  Bif = 'BIF',
  /** Bermudian Dollar. */
  Bmd = 'BMD',
  /** Brunei Dollar. */
  Bnd = 'BND',
  /** Bolivian Boliviano. */
  Bob = 'BOB',
  /** Brazilian Real. */
  Brl = 'BRL',
  /** Bahamian Dollar. */
  Bsd = 'BSD',
  /** Bhutanese Ngultrum. */
  Btn = 'BTN',
  /** Botswana Pula. */
  Bwp = 'BWP',
  /** Belarusian Ruble. */
  Byn = 'BYN',
  /** Belarusian Ruble. */
  Byr = 'BYR',
  /** Belize Dollar. */
  Bzd = 'BZD',
  /** Canadian Dollar. */
  Cad = 'CAD',
  /** Congolese Franc. */
  Cdf = 'CDF',
  /** Swiss Franc. */
  Chf = 'CHF',
  /** Unidad de Fomento. */
  Clf = 'CLF',
  /** Chilean Peso. */
  Clp = 'CLP',
  /** Chinese Renminbi Yuan. */
  Cny = 'CNY',
  /** Colombian Peso. */
  Cop = 'COP',
  /** Costa Rican Colón. */
  Crc = 'CRC',
  /** Cuban Convertible Peso. */
  Cuc = 'CUC',
  /** Cuban Peso. */
  Cup = 'CUP',
  /** Cape Verdean Escudo. */
  Cve = 'CVE',
  /** Cypriot pound. */
  Cyp = 'CYP',
  /** Czech Koruna. */
  Czk = 'CZK',
  /** Djiboutian Franc. */
  Djf = 'DJF',
  /** Danish Krone. */
  Dkk = 'DKK',
  /** Dominican Peso. */
  Dop = 'DOP',
  /** Algerian Dinar. */
  Dzd = 'DZD',
  /** Estonian Kroon. */
  Eek = 'EEK',
  /** Egyptian Pound. */
  Egp = 'EGP',
  /** Eritrean Nakfa. */
  Ern = 'ERN',
  /** Ethiopian Birr. */
  Etb = 'ETB',
  /** Euro. */
  Eur = 'EUR',
  /** Fijian Dollar. */
  Fjd = 'FJD',
  /** Falkland Pound. */
  Fkp = 'FKP',
  /** British Pound. */
  Gbp = 'GBP',
  /** British Penny. */
  Gbx = 'GBX',
  /** Georgian Lari. */
  Gel = 'GEL',
  /** Guernsey Pound. */
  Ggp = 'GGP',
  /** Ghanaian Cedi. */
  Ghc = 'GHC',
  /** Ghanaian Cedi. */
  Ghs = 'GHS',
  /** Gibraltar Pound. */
  Gip = 'GIP',
  /** Gambian Dalasi. */
  Gmd = 'GMD',
  /** Guinean Franc. */
  Gnf = 'GNF',
  /** Guatemalan Quetzal. */
  Gtq = 'GTQ',
  /** Guyanese Dollar. */
  Gyd = 'GYD',
  /** Hong Kong Dollar. */
  Hkd = 'HKD',
  /** Honduran Lempira. */
  Hnl = 'HNL',
  /** Croatian Kuna. */
  Hrk = 'HRK',
  /** Haitian Gourde. */
  Htg = 'HTG',
  /** Hungarian Forint. */
  Huf = 'HUF',
  /** Indonesian Rupiah. */
  Idr = 'IDR',
  /** Israeli New Sheqel. */
  Ils = 'ILS',
  /** Isle of Man Pound. */
  Imp = 'IMP',
  /** Indian Rupee. */
  Inr = 'INR',
  /** Iraqi Dinar. */
  Iqd = 'IQD',
  /** Iranian Rial. */
  Irr = 'IRR',
  /** Icelandic Króna. */
  Isk = 'ISK',
  /** Jersey Pound. */
  Jep = 'JEP',
  /** Jamaican Dollar. */
  Jmd = 'JMD',
  /** Jordanian Dinar. */
  Jod = 'JOD',
  /** Japanese Yen. */
  Jpy = 'JPY',
  /** Kenyan Shilling. */
  Kes = 'KES',
  /** Kyrgyzstani Som. */
  Kgs = 'KGS',
  /** Cambodian Riel. */
  Khr = 'KHR',
  /** Kiribati Dollar. */
  Kid = 'KID',
  /** Comorian Franc. */
  Kmf = 'KMF',
  /** North Korean Won. */
  Kpw = 'KPW',
  /** South Korean Won. */
  Krw = 'KRW',
  /** Kuwaiti Dinar. */
  Kwd = 'KWD',
  /** Cayman Islands Dollar. */
  Kyd = 'KYD',
  /** Kazakhstani Tenge. */
  Kzt = 'KZT',
  /** Lao Kip. */
  Lak = 'LAK',
  /** Lebanese Pound. */
  Lbp = 'LBP',
  /** Sri Lankan Rupee. */
  Lkr = 'LKR',
  /** Liberian Dollar. */
  Lrd = 'LRD',
  /** Lesotho Loti. */
  Lsl = 'LSL',
  /** Lithuanian Litas. */
  Ltl = 'LTL',
  /** Latvian Lats. */
  Lvl = 'LVL',
  /** Libyan Dinar. */
  Lyd = 'LYD',
  /** Moroccan Dirham. */
  Mad = 'MAD',
  /** Moldovan Leu. */
  Mdl = 'MDL',
  /** Malagasy Ariary. */
  Mga = 'MGA',
  /** Macedonian Denar. */
  Mkd = 'MKD',
  /** Myanmar Kyat. */
  Mmk = 'MMK',
  /** Mongolian Tögrög. */
  Mnt = 'MNT',
  /** Macanese Pataca. */
  Mop = 'MOP',
  /** Mauritanian Ouguiya. */
  Mro = 'MRO',
  /** Mauritanian New Ouguiya. */
  Mru = 'MRU',
  /** Maltese Lira. */
  Mtl = 'MTL',
  /** Mauritian Rupee. */
  Mur = 'MUR',
  /** Maldivian Rufiyaa. */
  Mvr = 'MVR',
  /** Malawian Kwacha. */
  Mwk = 'MWK',
  /** Mexican Peso. */
  Mxn = 'MXN',
  /** Malaysian Ringgit. */
  Myr = 'MYR',
  /** Mozambican Metical. */
  Mzn = 'MZN',
  /** Namibian Dollar. */
  Nad = 'NAD',
  /** Nigerian Naira. */
  Ngn = 'NGN',
  /** Nicaraguan Córdoba. */
  Nio = 'NIO',
  /** Norwegian Krone. */
  Nok = 'NOK',
  /** Nepalese Rupee. */
  Npr = 'NPR',
  /** New Zealand Dollar. */
  Nzd = 'NZD',
  /** Omani Rial. */
  Omr = 'OMR',
  /** Panamanian Balboa. */
  Pab = 'PAB',
  /** Peruvian Sol. */
  Pen = 'PEN',
  /** Papua New Guinean Kina. */
  Pgk = 'PGK',
  /** Philippine Peso. */
  Php = 'PHP',
  /** Pakistani Rupee. */
  Pkr = 'PKR',
  /** Polish Złoty. */
  Pln = 'PLN',
  /** Paraguayan Guaraní. */
  Pyg = 'PYG',
  /** Qatari Riyal. */
  Qar = 'QAR',
  /** Romanian Leu. */
  Ron = 'RON',
  /** Serbian Dinar. */
  Rsd = 'RSD',
  /** Russian Ruble. */
  Rub = 'RUB',
  /** Rwandan Franc. */
  Rwf = 'RWF',
  /** Saudi Riyal. */
  Sar = 'SAR',
  /** Solomon Islands Dollar. */
  Sbd = 'SBD',
  /** Seychellois Rupee. */
  Scr = 'SCR',
  /** Sudanese Pound. */
  Sdg = 'SDG',
  /** Swedish Krona. */
  Sek = 'SEK',
  /** Singapore Dollar. */
  Sgd = 'SGD',
  /** Saint Helenian Pound. */
  Shp = 'SHP',
  /** Slovak Koruna. */
  Skk = 'SKK',
  /** Sierra Leonean Leone. */
  Sll = 'SLL',
  /** Somali Shilling. */
  Sos = 'SOS',
  /** Surinamese Dollar. */
  Srd = 'SRD',
  /** South Sudanese Pound. */
  Ssp = 'SSP',
  /** São Tomé and Príncipe Dobra. */
  Std = 'STD',
  /** Salvadoran Colón. */
  Svc = 'SVC',
  /** Syrian Pound. */
  Syp = 'SYP',
  /** Swazi Lilangeni. */
  Szl = 'SZL',
  /** Thai Baht. */
  Thb = 'THB',
  /** Tajikistani Somoni. */
  Tjs = 'TJS',
  /** Turkmenistani Manat. */
  Tmm = 'TMM',
  /** Turkmenistani Manat. */
  Tmt = 'TMT',
  /** Tunisian Dinar. */
  Tnd = 'TND',
  /** Tongan Paʻanga. */
  Top = 'TOP',
  /** Turkish Lira. */
  Try = 'TRY',
  /** Trinidad and Tobago Dollar. */
  Ttd = 'TTD',
  /** New Taiwan Dollar. */
  Twd = 'TWD',
  /** Tanzanian Shilling. */
  Tzs = 'TZS',
  /** Ukrainian Hryvnia. */
  Uah = 'UAH',
  /** Ugandan Shilling. */
  Ugx = 'UGX',
  /** United States Dollar. */
  Usd = 'USD',
  /** Uruguayan Peso. */
  Uyu = 'UYU',
  /** Uzbekistan Som. */
  Uzs = 'UZS',
  /** Venezuelan Bolívar. */
  Veb = 'VEB',
  /** Venezuelan Bolívar fuerte. */
  Vef = 'VEF',
  /** Venezuelan Bolívar soberano. */
  Ves = 'VES',
  /** Vietnamese Đồng. */
  Vnd = 'VND',
  /** Vanuatu Vatu. */
  Vuv = 'VUV',
  /** Samoan Tala. */
  Wst = 'WST',
  /** Central African Cfa Franc. */
  Xaf = 'XAF',
  /** Silver (Troy Ounce). */
  Xag = 'XAG',
  /** Gold (Troy Ounce). */
  Xau = 'XAU',
  /** European Composite Unit. */
  Xba = 'XBA',
  /** European Monetary Unit. */
  Xbb = 'XBB',
  /** European Unit of Account 9. */
  Xbc = 'XBC',
  /** European Unit of Account 17. */
  Xbd = 'XBD',
  /** East Caribbean Dollar. */
  Xcd = 'XCD',
  /** Special Drawing Rights. */
  Xdr = 'XDR',
  /** UIC Franc. */
  Xfu = 'XFU',
  /** West African Cfa Franc. */
  Xof = 'XOF',
  /** Palladium. */
  Xpd = 'XPD',
  /** Cfp Franc. */
  Xpf = 'XPF',
  /** Platinum. */
  Xpt = 'XPT',
  /** Codes specifically reserved for testing purposes. */
  Xts = 'XTS',
  /** Yemeni Rial. */
  Yer = 'YER',
  /** South African Rand. */
  Zar = 'ZAR',
  /** Zambian Kwacha. */
  Zmk = 'ZMK',
  /** Zambian Kwacha. */
  Zmw = 'ZMW',
  /** Zimbabwean Dollar. */
  Zwd = 'ZWD',
  /** Zimbabwean Dollar. */
  Zwl = 'ZWL',
  /** Zimbabwean Dollar. */
  Zwn = 'ZWN',
  /** Zimbabwean Dollar. */
  Zwr = 'ZWR',
}

export type CursorPaginationInput = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type Developer = {
  __typename?: 'Developer';
  displayName?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  photoURL?: Maybe<Scalars['String']['output']>;
};

export type DeveloperTokenResponse = {
  __typename?: 'DeveloperTokenResponse';
  error?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

export type DevelopersResponse = {
  __typename?: 'DevelopersResponse';
  developers?: Maybe<Array<Developer>>;
  error?: Maybe<Scalars['String']['output']>;
};

export type ExportBillingItemsInput = {
  additionalRecipients?: InputMaybe<Array<Scalars['Email']['input']>>;
  columns: Array<ExportColumns>;
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type ExportBillingItemsResponse = {
  __typename?: 'ExportBillingItemsResponse';
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export enum ExportColumns {
  AffiliateCommission = 'AFFILIATE_COMMISSION',
  AffiliateCommissionPayer = 'AFFILIATE_COMMISSION_PAYER',
  CommissionDev = 'COMMISSION_DEV',
  CommissionPayer = 'COMMISSION_PAYER',
  CommissionRye = 'COMMISSION_RYE',
  CommissionStatus = 'COMMISSION_STATUS',
  CommissionTotal = 'COMMISSION_TOTAL',
  Credit = 'CREDIT',
  Currency = 'CURRENCY',
  Date = 'DATE',
  Discount = 'DISCOUNT',
  DiscountCodes = 'DISCOUNT_CODES',
  DiscountCommission = 'DISCOUNT_COMMISSION',
  DiscountCommissionDev = 'DISCOUNT_COMMISSION_DEV',
  DiscountCommissionPayer = 'DISCOUNT_COMMISSION_PAYER',
  DiscountCommissionRye = 'DISCOUNT_COMMISSION_RYE',
  FulfillmentFee = 'FULFILLMENT_FEE',
  ItemNames = 'ITEM_NAMES',
  Margin = 'MARGIN',
  MarkupCommission = 'MARKUP_COMMISSION',
  MarkupCommissionDev = 'MARKUP_COMMISSION_DEV',
  MarkupCommissionPayer = 'MARKUP_COMMISSION_PAYER',
  MarkupCommissionRye = 'MARKUP_COMMISSION_RYE',
  OrderId = 'ORDER_ID',
  PlatformFee = 'PLATFORM_FEE',
  Refund = 'REFUND',
  Shipping = 'SHIPPING',
  StoreName = 'STORE_NAME',
  StoreUrl = 'STORE_URL',
  Subtotal = 'SUBTOTAL',
  Tax = 'TAX',
  ThankYouPage = 'THANK_YOU_PAGE',
  Total = 'TOTAL',
}

export type InventoryRequestLimits = {
  __typename?: 'InventoryRequestLimits';
  uniqueProductRequests?: Maybe<Scalars['Int']['output']>;
  uniqueStoreRequests?: Maybe<Scalars['Int']['output']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  amount: Amount;
  billingPeriod: InvoiceBillingPeriod;
  createdAt: Scalars['DateTime']['output'];
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  items: Array<InvoiceItem>;
  number: Scalars['String']['output'];
  status: InvoiceStatus;
};

export type InvoiceBillingPeriod = {
  __typename?: 'InvoiceBillingPeriod';
  endDate: Scalars['DateTime']['output'];
  startDate: Scalars['DateTime']['output'];
};

export type InvoiceEdge = {
  __typename?: 'InvoiceEdge';
  cursor: Scalars['ConnectionCursor']['output'];
  node: Invoice;
};

export type InvoiceFiltersInput = {
  amount?: InputMaybe<AmountInput>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
};

export type InvoiceItem = {
  __typename?: 'InvoiceItem';
  amount: Amount;
  description: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
};

export type InvoicePaginationInput = {
  after?: InputMaybe<Scalars['ConnectionCursor']['input']>;
  before?: InputMaybe<Scalars['ConnectionCursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export enum InvoiceStatus {
  Draft = 'DRAFT',
  Paid = 'PAID',
  Unpaid = 'UNPAID',
  Void = 'VOID',
}

export type InvoicesResponse = {
  __typename?: 'InvoicesResponse';
  edges: Array<InvoiceEdge>;
  pageInfo: PageInfo;
};

export type MarginInput = {
  amount: Scalars['Int']['input'];
  isPercent: Scalars['Boolean']['input'];
};

export enum Marketplace {
  Amazon = 'AMAZON',
  Shopify = 'SHOPIFY',
}

export type Merchant = {
  __typename?: 'Merchant';
  commissionRate: Scalars['Int']['output'];
  commissionType: MerchantCommissionType;
  domain: Scalars['URL']['output'];
  logo?: Maybe<Scalars['URL']['output']>;
  name: Scalars['String']['output'];
};

export enum MerchantCommissionType {
  Discount = 'DISCOUNT',
  Standard = 'STANDARD',
}

export type MerchantsByDeveloperResponse = {
  __typename?: 'MerchantsByDeveloperResponse';
  merchants: Array<Merchant>;
};

/** Mutations to perform actions with Rye's admin API */
export type Mutation = {
  __typename?: 'Mutation';
  /** Create a new payment method */
  attachPaymentMethod?: Maybe<AttachPaymentMethodResponse>;
  /** Admin mutation to get custom token for logging into another developers account */
  createDeveloperToken: DeveloperTokenResponse;
  /** Admin mutation to kick of the process of exporting billing items */
  exportBillingItems: ExportBillingItemsResponse;
  /** Save a new webhook url */
  saveWebhookURL?: Maybe<SaveWebhookUrlResponse>;
  /** Updates the billing notification settings for a user */
  updateBillingNotifications: UpdateBillingNotificationsResponse;
  /** Update user */
  updateUser: UserResponse;
};

/** Mutations to perform actions with Rye's admin API */
export type MutationAttachPaymentMethodArgs = {
  input: AttachPaymentMethodInput;
};

/** Mutations to perform actions with Rye's admin API */
export type MutationCreateDeveloperTokenArgs = {
  input: CreateDeveloperTokenInput;
};

/** Mutations to perform actions with Rye's admin API */
export type MutationExportBillingItemsArgs = {
  input: ExportBillingItemsInput;
};

/** Mutations to perform actions with Rye's admin API */
export type MutationSaveWebhookUrlArgs = {
  input: SaveWebhookUrlInput;
};

/** Mutations to perform actions with Rye's admin API */
export type MutationUpdateBillingNotificationsArgs = {
  input: UpdateBillingNotificationsInput;
};

/** Mutations to perform actions with Rye's admin API */
export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type NotificationSettings = {
  __typename?: 'NotificationSettings';
  billingNotifications: BillingNotifications;
};

export type OffsetPaginationInput = {
  /** The number of items to fetch per page. The maximum value is 100. */
  limit?: Scalars['Int']['input'];
  /** The number of items to skip from the beginning of the list. */
  offset?: Scalars['Int']['input'];
};

export type Order = {
  __typename?: 'Order';
  api: ApiType;
  breakdown?: Maybe<OrderBreakdown>;
  commission?: Maybe<Commission>;
  id: Scalars['ID']['output'];
  marketplace: Marketplace;
  marketplaceOrderIds: Array<Scalars['String']['output']>;
  products: Array<Product>;
  transfers: Array<BillingTransfer>;
};

export type OrderBreakdown = {
  __typename?: 'OrderBreakdown';
  currency: Currency;
  discountCents: Scalars['Int']['output'];
  marginCents: Scalars['Int']['output'];
  refundCents: Scalars['Int']['output'];
  shippingCents: Scalars['Int']['output'];
  subtotalCents: Scalars['Int']['output'];
  taxCents: Scalars['Int']['output'];
  totalCents: Scalars['Int']['output'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']['output']>;
  startCursor?: Maybe<Scalars['String']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type PaymentMethod = PaymentMethodAch | PaymentMethodCard | PaymentMethodUnknown;

export type PaymentMethodAch = {
  __typename?: 'PaymentMethodACH';
  bank: Scalars['String']['output'];
  last4AccountNumber: Scalars['String']['output'];
};

export type PaymentMethodCard = {
  __typename?: 'PaymentMethodCard';
  brand: Scalars['String']['output'];
  expMonth: Scalars['Int']['output'];
  expYear: Scalars['Int']['output'];
  last4: Scalars['String']['output'];
};

export type PaymentMethodUnknown = {
  __typename?: 'PaymentMethodUnknown';
  brand: Scalars['String']['output'];
};

export type Product = {
  productId: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
};

/** Query operations to look up data within Rye's admin API */
export type Query = {
  __typename?: 'Query';
  billingItems: BillingItemResponse;
  developers: DevelopersResponse;
  invoices: InvoicesResponse;
  merchantsByDeveloper: MerchantsByDeveloperResponse;
  orderById: Order;
  user: UserResponse;
};

/** Query operations to look up data within Rye's admin API */
export type QueryBillingItemsArgs = {
  filters?: InputMaybe<BillingFiltersInput>;
  pagination?: InputMaybe<BillingItemsPaginationInput>;
};

/** Query operations to look up data within Rye's admin API */
export type QueryInvoicesArgs = {
  filters?: InputMaybe<InvoiceFiltersInput>;
  pagination?: InputMaybe<InvoicePaginationInput>;
};

/** Query operations to look up data within Rye's admin API */
export type QueryOrderByIdArgs = {
  id: Scalars['ID']['input'];
};

export type SaveWebhookUrlInput = {
  url: Scalars['String']['input'];
};

export type SaveWebhookUrlResponse = {
  __typename?: 'SaveWebhookURLResponse';
  url: Scalars['String']['output'];
  verified: Scalars['Boolean']['output'];
};

export type ShopifyProduct = Product & {
  __typename?: 'ShopifyProduct';
  productId: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  variantId: Scalars['String']['output'];
};

export type Spreedly = {
  __typename?: 'Spreedly';
  envSecret: Scalars['String']['output'];
  envToken: Scalars['String']['output'];
};

export type StripeConfig = {
  __typename?: 'StripeConfig';
  customerId: Scalars['String']['output'];
  paymentMethod?: Maybe<PaymentMethod>;
};

export type UpdateBillingNotificationsInput = {
  emails: Array<Scalars['Email']['input']>;
};

export type UpdateBillingNotificationsResponse = {
  __typename?: 'UpdateBillingNotificationsResponse';
  error?: Maybe<Scalars['String']['output']>;
  settings?: Maybe<BillingNotifications>;
};

export type UpdateUserInput = {
  account?: InputMaybe<UserAccountInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  adminToken?: Maybe<Scalars['String']['output']>;
  amazonBusinessConfig?: Maybe<AmazonBusinessConfig>;
  amazonGroupId?: Maybe<Scalars['String']['output']>;
  apiKey: Scalars['String']['output'];
  authProvider: AuthProvider;
  displayName?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  hmacSecretKey?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inventoryRequestLimits?: Maybe<InventoryRequestLimits>;
  isApprovedForDirectCheckout?: Maybe<Scalars['Boolean']['output']>;
  jwtValidationKey?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  marginAmount?: Maybe<Scalars['Float']['output']>;
  marginIsPercent?: Maybe<Scalars['Boolean']['output']>;
  photoURL?: Maybe<Scalars['String']['output']>;
  spreedly?: Maybe<Spreedly>;
  webhookURL?: Maybe<Scalars['String']['output']>;
  webhookURLVerified?: Maybe<Scalars['Boolean']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type UserAccountInput = {
  jwtValidationKey?: InputMaybe<Scalars['String']['input']>;
  margin?: InputMaybe<MarginInput>;
};

export type UserData = {
  __typename?: 'UserData';
  billingPeriodSettings: BillingPeriodSettings;
  notificationSettings: NotificationSettings;
  stripeConfig: StripeConfig;
  user: User;
};

export type UserResponse = {
  __typename?: 'UserResponse';
  data?: Maybe<UserData>;
  error?: Maybe<Scalars['String']['output']>;
};

export type VolumeTier = {
  __typename?: 'VolumeTier';
  fee: Scalars['Float']['output'];
  volume: Scalars['Int']['output'];
};

export type AttachPaymentMethodMutationVariables = Exact<{
  input: AttachPaymentMethodInput;
}>;

export type AttachPaymentMethodMutation = {
  __typename?: 'Mutation';
  attachPaymentMethod?: {
    __typename?: 'AttachPaymentMethodResponse';
    stripeConfig: {
      __typename?: 'StripeConfig';
      customerId: string;
      paymentMethod?:
        | { __typename?: 'PaymentMethodACH'; last4AccountNumber: string; bank: string }
        | {
            __typename?: 'PaymentMethodCard';
            last4: string;
            brand: string;
            expMonth: number;
            expYear: number;
          }
        | { __typename?: 'PaymentMethodUnknown'; brand: string }
        | null;
    };
  } | null;
};

export type UpdateBillingNotificationsMutationVariables = Exact<{
  input: UpdateBillingNotificationsInput;
}>;

export type UpdateBillingNotificationsMutation = {
  __typename?: 'Mutation';
  updateBillingNotifications: {
    __typename?: 'UpdateBillingNotificationsResponse';
    error?: string | null;
    settings?: { __typename?: 'BillingNotifications'; emails?: Array<string> | null } | null;
  };
};

export type SaveWebhookUrlMutationVariables = Exact<{
  input: SaveWebhookUrlInput;
}>;

export type SaveWebhookUrlMutation = {
  __typename?: 'Mutation';
  saveWebhookURL?: { __typename?: 'SaveWebhookURLResponse'; url: string; verified: boolean } | null;
};

export type MerchantsByDeveloperQueryVariables = Exact<{ [key: string]: never }>;

export type MerchantsByDeveloperQuery = {
  __typename?: 'Query';
  merchantsByDeveloper: {
    __typename?: 'MerchantsByDeveloperResponse';
    merchants: Array<{
      __typename?: 'Merchant';
      commissionRate: number;
      commissionType: MerchantCommissionType;
      domain: any;
      logo?: any | null;
      name: string;
    }>;
  };
};

export type BillingItemsQueryVariables = Exact<{
  pagination?: InputMaybe<BillingItemsPaginationInput>;
  filters?: InputMaybe<BillingFiltersInput>;
}>;

export type BillingItemsQuery = {
  __typename?: 'Query';
  billingItems: {
    __typename?: 'BillingItemResponse';
    edges: Array<{
      __typename?: 'BillingItemEdge';
      cursor: string;
      node: {
        __typename?: 'BillingItem';
        createdAt: string;
        id: string;
        orderId: string;
        type: BillingItemType;
        payer: BillingItemParty;
        receiver: BillingItemParty;
        amount: { __typename?: 'Amount'; currency: Currency; value: number };
        billingSetting: {
          __typename?: 'BillingSetting';
          developerCommissionShare: number;
          merchantCommissionRate: number;
          volumeTiers?: Array<{ __typename?: 'VolumeTier'; volume: number; fee: number }> | null;
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage?: boolean | null;
      hasPreviousPage?: boolean | null;
      startCursor?: string | null;
      endCursor?: string | null;
      totalPages?: number | null;
    };
  };
};

export type InvoicesQueryVariables = Exact<{
  filters?: InputMaybe<InvoiceFiltersInput>;
}>;

export type InvoicesQuery = {
  __typename?: 'Query';
  invoices: {
    __typename?: 'InvoicesResponse';
    edges: Array<{
      __typename?: 'InvoiceEdge';
      cursor: string;
      node: {
        __typename?: 'Invoice';
        createdAt: string;
        dueDate?: string | null;
        number: string;
        status: InvoiceStatus;
        amount: { __typename?: 'Amount'; value: number; currency: Currency };
        billingPeriod: { __typename?: 'InvoiceBillingPeriod'; startDate: string; endDate: string };
        items: Array<{
          __typename?: 'InvoiceItem';
          description: string;
          quantity: number;
          amount: { __typename?: 'Amount'; value: number; currency: Currency };
        }>;
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage?: boolean | null;
      hasPreviousPage?: boolean | null;
      startCursor?: string | null;
      endCursor?: string | null;
    };
  };
};

export type OrderByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type OrderByIdQuery = {
  __typename?: 'Query';
  orderById: {
    __typename?: 'Order';
    marketplaceOrderIds: Array<string>;
    marketplace: Marketplace;
    breakdown?: {
      __typename?: 'OrderBreakdown';
      subtotalCents: number;
      totalCents: number;
      taxCents: number;
      discountCents: number;
      marginCents: number;
      currency: Currency;
      shippingCents: number;
      refundCents: number;
    } | null;
    commission?: { __typename?: 'Commission'; rate: number; status: CommissionStatus } | null;
    products: Array<
      | { __typename?: 'AmazonProduct'; productId: string; quantity: number }
      | { __typename?: 'ShopifyProduct'; variantId: string; productId: string; quantity: number }
    >;
  };
};

export type CreateDeveloperTokenMutationVariables = Exact<{
  input: CreateDeveloperTokenInput;
}>;

export type CreateDeveloperTokenMutation = {
  __typename?: 'Mutation';
  createDeveloperToken: {
    __typename?: 'DeveloperTokenResponse';
    token?: string | null;
    error?: string | null;
  };
};

export type DevelopersQueryVariables = Exact<{ [key: string]: never }>;

export type DevelopersQuery = {
  __typename?: 'Query';
  developers: {
    __typename?: 'DevelopersResponse';
    error?: string | null;
    developers?: Array<{
      __typename?: 'Developer';
      id: string;
      email: string;
      displayName?: string | null;
      photoURL?: string | null;
    }> | null;
  };
};

export type ExportBillingItemsMutationVariables = Exact<{
  input: ExportBillingItemsInput;
}>;

export type ExportBillingItemsMutation = {
  __typename?: 'Mutation';
  exportBillingItems: {
    __typename?: 'ExportBillingItemsResponse';
    success: boolean;
    error?: string | null;
  };
};

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser: {
    __typename?: 'UserResponse';
    error?: string | null;
    data?: {
      __typename?: 'UserData';
      user: {
        __typename?: 'User';
        firstName?: string | null;
        lastName?: string | null;
        displayName?: string | null;
        apiKey: string;
        adminToken?: string | null;
        authProvider: AuthProvider;
        email: string;
        id: string;
        webhookURL?: string | null;
        webhookURLVerified?: boolean | null;
        marginAmount?: number | null;
        marginIsPercent?: boolean | null;
        photoURL?: string | null;
        isApprovedForDirectCheckout?: boolean | null;
        website?: string | null;
        jwtValidationKey?: string | null;
        hmacSecretKey?: string | null;
        spreedly?: { __typename?: 'Spreedly'; envToken: string; envSecret: string } | null;
        amazonBusinessConfig?: {
          __typename?: 'AmazonBusinessConfig';
          businessType?: AmazonBusinessType | null;
          groupId?: string | null;
          email: string;
          pendingGroupAccess?: boolean | null;
          token?: {
            __typename?: 'AmazonToken';
            type: AmazonTokenType;
            status: AmazonTokenStatus;
          } | null;
        } | null;
        inventoryRequestLimits?: {
          __typename?: 'InventoryRequestLimits';
          uniqueStoreRequests?: number | null;
          uniqueProductRequests?: number | null;
        } | null;
      };
      billingPeriodSettings:
        | {
            __typename?: 'BillingPerGMV';
            remainingGMV: number;
            currency: Currency;
            gracePeriodDays: number;
            lastInvoiceDate: string;
          }
        | {
            __typename?: 'BillingPerPeriod';
            gracePeriodDays: number;
            days: number;
            startDate: string;
            endDate: string;
          }
        | {
            __typename?: 'BillingPrePayment';
            currency: Currency;
            minBalance: number;
            gracePeriodDays: number;
            remainingBalance: number;
            lastRefillDate: string;
          };
      stripeConfig: {
        __typename?: 'StripeConfig';
        customerId: string;
        paymentMethod?:
          | { __typename?: 'PaymentMethodACH'; last4AccountNumber: string; bank: string }
          | {
              __typename?: 'PaymentMethodCard';
              last4: string;
              brand: string;
              expMonth: number;
              expYear: number;
            }
          | { __typename?: 'PaymentMethodUnknown'; brand: string }
          | null;
      };
      notificationSettings: {
        __typename?: 'NotificationSettings';
        billingNotifications: {
          __typename?: 'BillingNotifications';
          emails?: Array<string> | null;
        };
      };
    } | null;
  };
};

export type UserQueryVariables = Exact<{ [key: string]: never }>;

export type UserQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'UserResponse';
    error?: string | null;
    data?: {
      __typename?: 'UserData';
      user: {
        __typename?: 'User';
        firstName?: string | null;
        lastName?: string | null;
        displayName?: string | null;
        apiKey: string;
        adminToken?: string | null;
        authProvider: AuthProvider;
        email: string;
        id: string;
        webhookURL?: string | null;
        webhookURLVerified?: boolean | null;
        marginAmount?: number | null;
        marginIsPercent?: boolean | null;
        photoURL?: string | null;
        isApprovedForDirectCheckout?: boolean | null;
        website?: string | null;
        jwtValidationKey?: string | null;
        hmacSecretKey?: string | null;
        spreedly?: { __typename?: 'Spreedly'; envToken: string; envSecret: string } | null;
        amazonBusinessConfig?: {
          __typename?: 'AmazonBusinessConfig';
          businessType?: AmazonBusinessType | null;
          groupId?: string | null;
          email: string;
          pendingGroupAccess?: boolean | null;
          token?: {
            __typename?: 'AmazonToken';
            type: AmazonTokenType;
            status: AmazonTokenStatus;
          } | null;
        } | null;
        inventoryRequestLimits?: {
          __typename?: 'InventoryRequestLimits';
          uniqueStoreRequests?: number | null;
          uniqueProductRequests?: number | null;
        } | null;
      };
      billingPeriodSettings:
        | {
            __typename?: 'BillingPerGMV';
            remainingGMV: number;
            currency: Currency;
            gracePeriodDays: number;
            lastInvoiceDate: string;
          }
        | {
            __typename?: 'BillingPerPeriod';
            gracePeriodDays: number;
            days: number;
            startDate: string;
            endDate: string;
          }
        | {
            __typename?: 'BillingPrePayment';
            currency: Currency;
            minBalance: number;
            gracePeriodDays: number;
            remainingBalance: number;
            lastRefillDate: string;
          };
      stripeConfig: {
        __typename?: 'StripeConfig';
        customerId: string;
        paymentMethod?:
          | { __typename?: 'PaymentMethodACH'; last4AccountNumber: string; bank: string }
          | {
              __typename?: 'PaymentMethodCard';
              last4: string;
              brand: string;
              expMonth: number;
              expYear: number;
            }
          | { __typename?: 'PaymentMethodUnknown'; brand: string }
          | null;
      };
      notificationSettings: {
        __typename?: 'NotificationSettings';
        billingNotifications: {
          __typename?: 'BillingNotifications';
          emails?: Array<string> | null;
        };
      };
    } | null;
  };
};

export const AttachPaymentMethodDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'attachPaymentMethod' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AttachPaymentMethodInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachPaymentMethod' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stripeConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'customerId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paymentMethod' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'PaymentMethodCard' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'PaymentMethodACH' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'last4AccountNumber' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'bank' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'PaymentMethodUnknown' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AttachPaymentMethodMutation, AttachPaymentMethodMutationVariables>;
export const UpdateBillingNotificationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateBillingNotifications' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateBillingNotificationsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBillingNotifications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'emails' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateBillingNotificationsMutation,
  UpdateBillingNotificationsMutationVariables
>;
export const SaveWebhookUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'saveWebhookURL' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SaveWebhookURLInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saveWebhookURL' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SaveWebhookUrlMutation, SaveWebhookUrlMutationVariables>;
export const MerchantsByDeveloperDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'merchantsByDeveloper' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchantsByDeveloper' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'merchants' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'commissionRate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'commissionType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MerchantsByDeveloperQuery, MerchantsByDeveloperQueryVariables>;
export const BillingItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BillingItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BillingItemsPaginationInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BillingFiltersInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'amount' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'payer' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'receiver' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'billingSetting' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'developerCommissionShare' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'merchantCommissionRate' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'volumeTiers' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'fee' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BillingItemsQuery, BillingItemsQueryVariables>;
export const InvoicesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'invoices' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'InvoiceFiltersInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invoices' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'amount' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'billingPeriod' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'amount' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'currency' },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InvoicesQuery, InvoicesQueryVariables>;
export const OrderByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OrderById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'marketplaceOrderIds' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'breakdown' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'subtotalCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'taxCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'discountCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marginCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shippingCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'refundCents' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'marketplace' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'commission' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'rate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'products' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'productId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ShopifyProduct' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrderByIdQuery, OrderByIdQueryVariables>;
export const CreateDeveloperTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createDeveloperToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateDeveloperTokenInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createDeveloperToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateDeveloperTokenMutation, CreateDeveloperTokenMutationVariables>;
export const DevelopersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'developers' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'developers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'developers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'photoURL' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DevelopersQuery, DevelopersQueryVariables>;
export const ExportBillingItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'exportBillingItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ExportBillingItemsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exportBillingItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ExportBillingItemsMutation, ExportBillingItemsMutationVariables>;
export const UpdateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateUserInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'apiKey' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'adminToken' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'authProvider' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'webhookURL' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'webhookURLVerified' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'marginAmount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'marginIsPercent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'photoURL' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isApprovedForDirectCheckout' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'spreedly' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'envToken' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'envSecret' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'amazonBusinessConfig' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'businessType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pendingGroupAccess' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'token' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'inventoryRequestLimits' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uniqueStoreRequests' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uniqueProductRequests' },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'jwtValidationKey' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hmacSecretKey' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'billingPeriodSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'BillingPerPeriod' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'gracePeriodDays' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'days' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'BillingPerGMV' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'remainingGMV' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'gracePeriodDays' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastInvoiceDate' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'BillingPrePayment' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'minBalance' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'gracePeriodDays' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'remainingBalance' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastRefillDate' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stripeConfig' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'customerId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'paymentMethod' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'PaymentMethodCard' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'expMonth' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'PaymentMethodACH' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'last4AccountNumber' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'bank' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'PaymentMethodUnknown' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notificationSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'billingNotifications' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'emails' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>;
export const UserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'user' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'apiKey' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'adminToken' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'authProvider' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'webhookURL' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'webhookURLVerified' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'marginAmount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'marginIsPercent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'photoURL' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isApprovedForDirectCheckout' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'spreedly' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'envToken' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'envSecret' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'amazonBusinessConfig' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'businessType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pendingGroupAccess' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'token' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'inventoryRequestLimits' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uniqueStoreRequests' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uniqueProductRequests' },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'jwtValidationKey' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hmacSecretKey' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'billingPeriodSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'BillingPerPeriod' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'gracePeriodDays' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'days' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'BillingPerGMV' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'remainingGMV' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'gracePeriodDays' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastInvoiceDate' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'BillingPrePayment' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'minBalance' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'gracePeriodDays' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'remainingBalance' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastRefillDate' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stripeConfig' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'customerId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'paymentMethod' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'PaymentMethodCard' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'expMonth' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'PaymentMethodACH' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'last4AccountNumber' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'bank' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'PaymentMethodUnknown' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notificationSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'billingNotifications' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'emails' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserQuery, UserQueryVariables>;
