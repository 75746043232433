import { useQuery } from '@apollo/client';
import { INVOICES } from 'app/graphql';
import { useAppSelector } from 'app/store';
import { selectUserInfo } from 'app/store/user';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card';
import { TabsContent } from 'components/ui/tabs';
import { Container, EmptyState, Image } from 'pages/Billing/styles';
import { useEffect, useRef, useState } from 'react';
import { format, isBefore } from 'date-fns';
import { columns, InvoiceItem } from './column';
import { DataTable } from 'components/ui/data-table';
import { capitalCase } from 'change-case';
import EmptyStateImg from 'assets/images/no-invoices.svg';
import { InvoicesQuery } from 'app/graphql/generated/admin/graphql';
import { Separator } from 'components/ui/separator';
import { BalanceContent, BillingPeriodContent, Label } from './styles';
import { Button } from 'components/ui/button';
import { InvoiceDialog } from '../InvoiceDialog';
import { Row } from '@tanstack/react-table';
import { ExportInvoices } from './ExportInvoices';
import { UTCDate } from '@date-fns/utc';

type Props = {
  value: string;
};

export const BillingInvoiceTabContent = ({ value }: Props) => {
  const { adminToken } = useAppSelector(selectUserInfo) || {};
  const [isOpen, setOpen] = useState(false);
  const [isExportOpen, setOpenExport] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<InvoiceItem | undefined>();
  const [loading, setLoading] = useState(true);
  const loadingTimeout = useRef<ReturnType<typeof setTimeout>>();

  const [selectedItem, setSelectedItem] = useState<InvoiceItem | undefined>();

  const { data, loading: queryLoading } = useQuery(INVOICES, {
    context: {
      clientName: 'admin',
    },
    skip: !adminToken,
    variables: {
      filters: { year: new Date().getFullYear().toString() },
    },
  });

  const selectedInvoiceData = selectedItem
    ? data?.invoices.edges[selectedItem.index]?.node
    : undefined;

  const handleActionItemClicked = (row: Row<InvoiceItem>) => {
    setSelectedInvoice(row.original);
    setOpenExport(true);
  };

  const handleCurrentInvoiceReport = (invoice: InvoiceItem) => {
    setSelectedInvoice(invoice);
    setOpenExport(true);
  };

  const tableData = transformTableData(data);

  useEffect(() => {
    loadingTimeout.current = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => {
      clearTimeout(loadingTimeout.current);
    };
  }, [data]);

  const handleRowClick = (row: InvoiceItem) => {
    setSelectedItem(row);
    setOpen(true);
  };

  const handleViewDetails = () => {
    setSelectedItem(tableData[0]);
    setOpen(true);
  };

  const currentInvoice = tableData[0];

  const getCurrentStatusTag = () => {
    switch (currentInvoice?.status) {
      case 'Paid':
        return 'Invoice has been paid';
      case 'Unpaid':
        return 'Invoice finalized and waiting payment';
      default:
        return 'Invoice is being finalized';
    }
  };

  return (
    <TabsContent value={value}>
      <Container>
        <InvoiceDialog
          open={isOpen}
          invoice={selectedInvoiceData}
          rowData={selectedItem}
          onOpenChange={setOpen}
        />
        {selectedInvoice && (
          <ExportInvoices
            isOpen={isExportOpen}
            onOpenChange={setOpenExport}
            invoiceItem={selectedInvoice}
          />
        )}
        {currentInvoice && (
          <Card className="mb-[20px] mt-[20px] inline-flex h-[150px] p-[24px]">
            <CardContent className="flex flex-row items-center gap-[70px] p-0">
              <BillingPeriodContent>
                <Label>Billing Period</Label>
                <CardTitle className="font-normal">{currentInvoice.billingPeriod}</CardTitle>
                <CardDescription>{getCurrentStatusTag()}</CardDescription>
              </BillingPeriodContent>

              <Separator orientation="vertical" className="h-[100%]" />

              <BalanceContent>
                <Label>Balance</Label>
                <CardTitle className="font-normal">
                  {currentInvoice.amountDue.split(' ')[0]}
                </CardTitle>
                <CardDescription>Due {format(currentInvoice.dueDate, 'MMMM do')}</CardDescription>
              </BalanceContent>
              <div className="flex flex-col gap-2">
                <Button onClick={handleViewDetails}>View Invoice Details</Button>
                <Button
                  variant="outline"
                  onClick={() => handleCurrentInvoiceReport(currentInvoice)}
                >
                  Export Billing Period
                </Button>
              </div>
            </CardContent>
          </Card>
        )}
        <Card>
          <CardHeader className="flex-auto flex-row justify-between">
            <div className="space-y-1">
              <CardTitle>Invoice History</CardTitle>
              <CardDescription>List of past invoices</CardDescription>
            </div>
          </CardHeader>
          <CardContent>
            <DataTable
              loading={loading || queryLoading}
              columns={columns}
              onRowActionClick={handleActionItemClicked}
              rowActions={[
                {
                  label: 'Export billing period',
                  id: 'export',
                },
              ]}
              data={tableData.filter(
                (item) =>
                  item.id !== currentInvoice?.id &&
                  item.status !== 'Draft' &&
                  item.status !== 'Void',
              )}
              hideToolbar={true}
              onRowClicked={handleRowClick}
              emptyState={
                <EmptyState>
                  <Image style={{ width: '200px' }} src={EmptyStateImg} />
                  <CardTitle>{'Invoice history is empty...'}</CardTitle>
                </EmptyState>
              }
            />
          </CardContent>
        </Card>
      </Container>
    </TabsContent>
  );
};

function transformTableData(invoices: InvoicesQuery | undefined | null): InvoiceItem[] {
  if (!invoices) return [];
  return invoices.invoices.edges
    .map((edge, index) => {
      // these dates are utc timestamps
      const startDate = new Date(edge.node.billingPeriod.startDate);
      const endDate = new Date(edge.node.billingPeriod.endDate);
      const numberArray = edge.node.number.split('-');
      const invoiceId = numberArray[numberArray.length - 1];
      return {
        index,
        id: invoiceId,
        billingPeriod: `${format(startDate, 'MMM do')} - ${format(endDate, 'MMM do')}`,
        amountDue: `${new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: edge.node.amount.currency,
        }).format(edge.node.amount.value / 100)} ${edge.node.amount.currency}`,
        dueDate: edge.node.dueDate
          ? format(new Date(edge.node.dueDate), 'MM/dd/yyyy')
          : 'No due date',
        status: capitalCase(edge.node.status),
        startDate: edge.node.billingPeriod.startDate,
        endDate: edge.node.billingPeriod.endDate,
      };
    })
    .sort((a, b) => (isBefore(new UTCDate(a.startDate), new UTCDate(b.endDate)) ? 1 : -1));
}
